import React from "react";
import deepmerge from "deepmerge";
import { CONSTANTS } from "./constants";
import Joi from "joi";
import { DateTime } from "luxon";

export const bimmatchSupportEmail = "support-team@bimmatch.com";

export const bimmatchOrgID = "00000000-0000-0000-0000-000000000000"

export const autodeskAuthVersion = 2

export const autodeskAuthUrl = `https://developer.api.autodesk.com/authentication/v${autodeskAuthVersion}`

const getCatalogCDNURL = (appender) => {
  const cdn = process.env.REACT_APP_AWS_CATALOG_CDN_DOMAIN;
  return `//${cdn}/${appender}`;
};

const getBimmatchCDNURL = (appender) => {
  const cdn = process.env.REACT_APP_AWS_BIMMATCH_CDN_DOMAIN;
  return `//${cdn}/${appender}`;
};

const generateImageUrl = (imgFile) => {
  const bucketName = imgFile?.Bucket;
  const key = imgFile?.Key;
  if (process.env.NODE_ENV === "development") {
    return imgFile?.location;
  }
  if (bucketName && key) {
    if (bucketName.includes("bimmatch-product-catalog")) {
      return getCatalogCDNURL(key);
    }
    if (bucketName.includes("bimmatch-storage")) {
      return getBimmatchCDNURL(key);
    }
  }
  return imgFile?.location;
}

export const getImgLocation = (images) => {
  if (!images) {
    return images
  }
  if (images && Array.isArray(images)) {
    return images.map((image) => ({
      ...image,
      url: generateImageUrl(image)
    }));
  }
  return { ...images, url: generateImageUrl(images) };
};

export const getCurrencyForCode = (code) => {
  switch (code) {
    case "NIS":
      return "₪";
    case "EURO":
      return "€";
    case "USD":
    default:
      return "$";
  }
};

export const deepmergeWithArray = (object1, object2) => {
  return deepmerge(object1, object2, {
    arrayMerge: (dest, src) => {
      return [...dest, ...src];
    },
  });
};

// highlights the searched match text
export const getHighlightedText = (text, highlight) => {
  const parts = text.split(new RegExp(`(${highlight})`, "gi"));

  return (
    <>
      {parts.map((part, i) => (
        <span
          key={`hightlight-${part + i}`}
          className={part.toLowerCase() === highlight ? "highlight-text" : "normal-text"}
        >
          {part}
        </span>
      ))}
    </>
  );
};

export const generateUuid = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

/**
 * @description function to set data in localstorage with expiry time
 *
 * @param key string - unique key for the data to be stored in localstorage
 * @param value  - value to be stored in localstorage for the passed key
 * @param expiresInTime number - time (in minutes) duration after which it should be considered expired (defalut - 120)
 */

export function setLocalStorageWithExpiry(key, value, expiresInTime = 120) {
  const now = new Date();
  const expiryTimeInMilliseconds = expiresInTime * 60000;

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value,
    expiry: now.getTime() + expiryTimeInMilliseconds,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

/**
 *function to get data from localstorage having expiry time
 *
 * @param key string - key of the data to be get from the localstorage
 */

export function getLocalStorageWithExpiry(key) {
  const itemStr = localStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

/**
 * @description function to deep copy object
 *
 * @param obj object - pass the object you want to be deep copied
 * @return object  - returns deep copied value
 */

export function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

/**
 * @description function to get custom section id (required for create product form)
 *
 * @param id string - the id from server
 * @return string  - custom id
 */

export function getSectionCustomId(id) {
  switch (id) {
    // Dimensions
    case "70d34cc0-0e4d-11ed-aaca-3513941289b3":
      return "dimensions";

    // Materials And Finishes
    case "70d7b990-0e4d-11ed-aaca-3513941289b3":
      return "materials-finishes";

    // Identity data
    case "70d807b0-0e4d-11ed-aaca-3513941289b3":
      return "identity-data";

    // Environment codes - Possible compliance
    case "70d855d0-0e4d-11ed-aaca-3513941289b3":
      return "environmental-codes";

    // Features
    case "70d87ce0-0e4d-11ed-aaca-3513941289b3":
      return "features";

    // Pricing and warranty
    case "70d8cb00-0e4d-11ed-aaca-3513941289b3":
      return "pricing-warranty";

    // BIM, Spec and other files
    case "70d8f210-0e4d-11ed-aaca-3513941289b3":
      return "files";

    // Demos
    case "70d91920-0e4d-11ed-aaca-3513941289b3":
      return "demo-clips";

    // Hardware set
    case "1ef116f0-c47d-46f2-abe6-a71482ed8c70":
      return "hardware-set";

    // Structure
    case "dd45905f-c555-43b5-bb5e-17c3b01cc689":
      return "structure";

    // GHG emission
    case "02df7f6a-86f3-477c-a2bb-20c27b9aceb7":
      return "ghg-emission";

    // Miscellaneous
    case "b1afa84d-7c89-468a-b76b-0207d976f5f9":
      return "miscellaneous";

    // RFA uploaded parameters
    case "11ca7fb7-4037-4d41-aef4-82a4bd26a1d7":
      return "rfa-parameters";

    // Valves
    case "474bdf0b-9b0d-4a9d-88b5-c85f47e0429c":
      return "valves";

    default:
      return id;
  }
}

export const cmsStaticPropertyIds = {
  disciplines: "ac29f699-c006-4ef3-80bf-dcfd8926b45c",
  searchTags: "db18b792-ca0a-43ef-8fe8-e1da60ce8997",
  materials: "993a4940-1d4b-11ed-ab90-874bd71c6fc7",
};

export const PRODUCT_PROPERTY_MAPPINGS = {
  materials: cmsStaticPropertyIds.materials,
  revitFile: "9945e200-1d4b-11ed-ab90-874bd71c6fc7",
  cadFile: "99463020-1d4b-11ed-ab90-874bd71c6fc7",
  specFile: "99465730-1d4b-11ed-ab90-874bd71c6fc7",
  installationFile: "99467e40-1d4b-11ed-ab90-874bd71c6fc7",
  textureFile: "031fda12-61ad-41b0-a32e-a5c7bb4764f7",
  priceCurrency: "a61e8bb7-262c-4131-b266-a4b02d8193cc",
  priceTotal: "1e86da4e-2454-486a-95d9-7d4e37ae9e58",
  priceUnit: "f3b82458-6266-43b3-b8fc-d523cea9fb5b",
  priceForUnits: "994593e0-1d4b-11ed-ab90-874bd71c6fc7",
  gwp: "99425f91-1d4b-11ed-ab90-874bd71c6fc7",
  division: "993cba41-1d4b-11ed-ab90-874bd71c6fc7",
  discipline: cmsStaticPropertyIds.discipline,
};

export const extractProductPropertyValue = (propertyValueOptions, product) => {
  const regexExpUUID =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

  const propertyValues = {};

  const mergedArray = [
    ...(product?.properties || []),
    ...(product?.files || []),
  ];

  if (mergedArray.length === 0) return propertyValues;

  Object.keys(PRODUCT_PROPERTY_MAPPINGS).forEach((key) => {
    const filtered = mergedArray.filter(
      (obj) => PRODUCT_PROPERTY_MAPPINGS[key] === obj.id
    );

    if (filtered.length > 0) {
      propertyValues[key] = filtered[0].value || filtered[0].values;

      if (
        Array.isArray(propertyValues[key]) &&
        regexExpUUID.test(propertyValues[key][0])
      ) {
        const tempPropertyValueArray = [];
        propertyValues[key].forEach((id) => {
          const tempPropertyValue = propertyValueOptions.find(
            (valueOption) => valueOption.id === id
          );
          tempPropertyValue?.displayName &&
            tempPropertyValueArray.push(tempPropertyValue.displayName);
        });

        propertyValues[key] = tempPropertyValueArray;
      } else if (regexExpUUID.test(propertyValues[key])) {
        propertyValues[key] = propertyValueOptions.find(
          (valueOption) => valueOption.id === propertyValues[key]
        );
      }
    }
  });

  return propertyValues;
};

export function findObjByKeyValue(obj, targetKey, targetValue) {
  for (let key in obj) {
    const val = obj[key];
    if (val === targetValue && key === targetKey) {
      return obj;
    }
    if (typeof val === "object") {
      const ret = findObjByKeyValue(val, targetKey, targetValue);
      if (ret) return ret;
    }
  }
}

/**
 * @description function to get categories in tree herirarchy 
 *
 * @param categories array - all the list of categories including parent and sub
 * @return array  - tree herirarchy of categories as sub categories in children
*/

export const modelingCategoriesHierarchy = (categories) => {
  if (categories.length) {
    const categoriesClone = deepClone(categories);
    categoriesClone.forEach((ele) => {
      ele.title = ele.displayName;
      ele.key = ele.id;
      if (ele.parentCategoryId) {
        const extractedObj = findObjByKeyValue(
          categoriesClone,
          "id",
          ele.parentCategoryId
        );
        extractedObj.children = extractedObj?.children
          ? [...extractedObj?.children, ele]
          : [ele];
      }
    });
    const finalCategoriesHierarchy = categoriesClone.filter(
      (ele) => !ele.parentCategoryId
    );
    return finalCategoriesHierarchy;
  }
};

/**
 * @description function to get filtered sub categories according to selected parent categories for select type category
 *
 * @param allCategories array - all the list of categories including parent and sub
 * @param selectedParentCategories array - ids of selected parent categories
 * @return array  - structed array of filtered subcategories
*/
export const filteredSubCatAccToParentCat = (allCategories = [], selectedParentCategories = []) => {
  let subCategories = []

  if (!allCategories.length) {
    return subCategories
  }

  subCategories = modelingCategoriesHierarchy(allCategories).filter(parentCategory => {
    const toInclude = selectedParentCategories.includes(parentCategory.id)
    if (toInclude) {
      parentCategory.buckets = parentCategory?.children || []
      delete parentCategory?.children
    }
    return toInclude
  }
  )

  return subCategories
}

export const checkAllKeysWithValueExistsInObj = (requiredKeys, obj) => {
  let checkAllKeys = false;

  if (requiredKeys.length) {
    checkAllKeys = requiredKeys.every((key) => !!obj?.[key]);
  }

  return checkAllKeys;
};

export const getAllKeysWithValueInObj = (obj) => {
  return Object.assign(
    ...Object.keys(obj)
      .filter((key) => !!obj[key])
      .map((key) => ({ [key]: obj[key] }))
  );
};

export const isPlanner = (plan) => {
  return CONSTANTS.PLAN.PLANNER_PLANS.includes(plan);
};

export const isVendor = (plan) => {
  return CONSTANTS.PLAN.VENDOR_PLANS.includes(plan);
};


// -------- Start --- Field validation ----------------------------------------
export const fieldValidation = (rule, value, label) => {
  let isValid = true;
  let errorMessage, joi
  let allErrorMessages

  const supportedTypes = ["string", "url", "number", "select", "file", "password"];
  const isFieldSupported = rule?.type && supportedTypes.includes(rule.type);

  const emailReg = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);

  if (isFieldSupported && rule && Object.keys(rule).length) {
    //commmon rules
    function commonRuleCheck() {
      if (rule?.pattern) joi = joi.pattern(rule?.pattern, "Value");
      if (rule?.required) joi = joi.required();
    }

    function minMaxRule() {
      if (rule?.max) joi = joi.max(rule.max);
      if (rule?.min) joi = joi.min(rule.min);
    }

    //string
    if (rule?.type === "string" || rule?.type === "number") {
      joi = rule.type === "string" ? Joi.string().messages({ "string.pattern.base": "Not a valid value" }) : Joi.number();
      minMaxRule();
      commonRuleCheck();
      if (rule?.type === "string" && rule?.email) {
        if (!value.match(emailReg)) {
          isValid = false;
          errorMessage = "Email is not valid";
        }
      }

      if (rule?.type === "string" && rule?.emails) {
        const emails = (value || "").split(",").map((email) => email.trim());
        for (const email of emails) {
          if (!email.match(emailReg)) {
            isValid = false;
            errorMessage = "Emails is not valid";
            break;
          }
        }
      }
    }

    // password strength validation
    if (rule?.type === "password") {

      const passwordErrors = {}

      const validationTypes = {
        number: {
          validationRegex: /\d/,
          errorMessage: `${label || "Value"}  must contain at least one number.`
        },
        specialCharacter: {
          validationRegex: /[!@#$%^&*(),.?":{}|<>]/,
          errorMessage: `${label || "Value"}  must contain at least one special character.`
        },
        capital: {
          validationRegex: /[A-Z]/,
          errorMessage: `${label || "Value"}  must contain at least one uppercase letter.`
        },
        min: {
          validationRegex: new RegExp(`^.{${rule?.min || 8},}$`),
          errorMessage: `${label || "Value"} must be at least ${rule?.min || 8} characters long.`
        }
      }

      for (const vaildationType in validationTypes) {
        if (!rule?.[vaildationType]) {
          continue;
        }

        if (!validationTypes[vaildationType].validationRegex.test(value)) {
          isValid = false
          passwordErrors[vaildationType] = validationTypes[vaildationType].errorMessage
        }
      }

      if (Object.keys(passwordErrors).length) {
        allErrorMessages = passwordErrors
      }

    }

    if (rule?.type === "url") {
      joi = Joi.string().uri().messages({
        "string.uri": "Not a valid URL",
        "string.pattern.base": "Not a valid URL",
      });
      commonRuleCheck();
    }

    if (rule?.type === "select") {
      joi = Joi.array().messages({
        "array.min": `Select atleast ${rule?.min} options`,
        "array.max": `Should select less than ${rule?.max} options`,
        "array.includesRequiredUnknowns": label
          ? `${label} is a required field`
          : "Select is required",
      });

      minMaxRule();
      if (rule?.required) joi = joi.items(Joi.string().required());
    }


    // finally checking all validation
    if (value || rule?.required) {
      if (rule?.type === "file") {
        isValid = rule?.required && !value ? false : true;
        errorMessage = `${label || "Value"}  is required`;
      } else if (rule?.type !== "password"){
        const { error } = joi.validate(value);
        if (error && error?.details[0]?.message) {
          isValid = false;
          const errorMessageIntial = error.details[0].message;
          errorMessage = errorMessageIntial
            .replace('"value"', label || "Value")
            .trim();
        }
      }
    } else {
      isValid = true;
      errorMessage = "";
    }
  }

  if (rule?.type === "number" && !value && rule?.required) {
    errorMessage = `${label || "Value"}  is required`
  }

  const finalData = {
    isValid,
  };

  if (errorMessage) {
    finalData.message = errorMessage;
  }

  // Note: supported only for type password
  if (allErrorMessages) {
    finalData.allMessages = allErrorMessages
  }

  return finalData;
};


//------ End --------- Field validation ----------------------------------------


// get and set cookie
export const setCookie = (name, value, days = 7) => {
  let expires = "";
  let date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  expires = "; expires=" + date.toUTCString();
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

export const getCookie = (name) => {
  let nameEQ = name + "=";
  let cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) === " ")
      cookie = cookie.substring(1, cookie.length);
    if (cookie.indexOf(nameEQ) === 0)
      return cookie.substring(nameEQ.length, cookie.length);
  }
  return null;
};

export const deleteCookie = (name) => {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

export const windowPopupCenter = ({ url, title, width, height }) => {
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const systemZoom = width / window.screen.availWidth;
  const left = width / 2 / systemZoom + dualScreenLeft;
  const top = 300;
  const newWindow = window.open(
    url,
    title,
    `
    scrollbars=yes,
    width=${width}, 
    height=${height}, 
    top=${top}, 
    left=${left}
    `
  );

  if (window.focus) newWindow.focus();
};

export const isUUID = (str) =>
  /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
    str
  );

export const getBrandName = (productData) => {
  // default brand name for bimmatch product or free plan
  let brandName = (productData?.brand && productData?.brand?.id) ? productData.brand.displayName : "";
  const orgName = (
    <>
      {" "}
      {productData?.organizationLogo && (
        <img
          className="org-logo"
          src={getImgLocation(productData?.organizationLogo)?.url}
          alt="brand"
        />
      )}{" "}
      <span className="org-name">{productData.organizationName || ""}</span>
    </>
  );
  if (productData.organizationId !== bimmatchOrgID) {
    if (isVendor(productData.organizationPlan)) {
      // vendor
      brandName = (brandName && (productData?.brand?.id !== productData?.organizationBrandId)) ? (
        <>
          {orgName}
          <span className="brand-name">{brandName}</span>
        </>
      ) : orgName;
    } else if (isPlanner(productData.organizationPlan)) {
      // planner
      brandName = orgName;
    }
  }
  return brandName;
};

// -- Units -- ///**
/**
 * @description function to convert unit from metric to imperial and vice-versa
 *
 * @param unit string - unit that has to be converted
 * @param value string - value associated with the unit that has to be converted accordingly (optional)
 * @param type string - to which type (metric/imperial) of unit to be converted (optianal)
 *
 * @return object  - returns converted unit and value
 */

export const getUnitConversion = (unit, value, type) => {
  const unitType = type || localStorage.getItem("unit") || "metric";
  const parsedValue = value ? parseFloat(value) : "";
  let convertedUnit = unit;
  let convertedValue = parsedValue || "";

  if (unitType === "metric") {
    // reverse unit conversion (used for CMS) {-do not add/update units unless supported in backend-}
    switch (unit) {
      case "ft":
        convertedUnit = "m";
        convertedValue = parsedValue ? parsedValue / 3.281 : "";
        break;

      case "ft^2":
        convertedUnit = "m^2";
        convertedValue = parsedValue ? parsedValue / 10.764 : "";
        break;

      case "ft^3":
        convertedUnit = "m^3";
        convertedValue = parsedValue ? parsedValue / 35.315 : "";
        break;

      case "lb":
        convertedUnit = "kg";
        convertedValue = parsedValue ? parsedValue * 0.453 : "";
        break;

      default:
        convertedUnit = unit;
        convertedValue = parsedValue;
    }
  } else {
    // unit conversion
    switch (unit) {
      // meter
      case "m":
        convertedUnit = "ft";
        convertedValue = parsedValue ? parsedValue * 3.281 : "";
        break;

      case "m^2":
        convertedUnit = "ft^2";
        convertedValue = parsedValue ? parsedValue * 10.764 : "";
        break;

      case "m^3":
        convertedUnit = "ft^3";
        convertedValue = parsedValue ? parsedValue * 35.3147 : "";
        break;

      // centimeter
      case "cm":
        convertedUnit = "ft";
        convertedValue = parsedValue ? parsedValue / 30.48 : "";
        break;

      case "cm^2":
        convertedUnit = "ft^2";
        convertedValue = parsedValue ? parsedValue / 929.03 : "";
        break;

      case "cm^3":
        convertedUnit = "ft^3";
        convertedValue = parsedValue ? parsedValue / 28316.84 : "";
        break;

      // kilogram
      case "kg":
        convertedUnit = "lb";
        convertedValue = parsedValue ? parsedValue * 2.205 : "";
        break;

      //--- for labels----//

      case "sqm":
        convertedUnit = "sqf";
        break;

      default:
        convertedUnit = unit;
        convertedValue = parsedValue;
    }
  }

  return {
    unit: convertedUnit,
    value: convertedValue
      ? parseFloat(convertedValue.toFixed(3)).toString()
      : "",
  };
};

export const privateDebug = (...data) => {
  if (localStorage.getItem("_DEBUG") === "true") {
    console.log(data);
  }
};

export const getValueFromBomProperties = (
  properties,
  key,
  defaultValue = undefined
) => {
  if (!properties) {
    return undefined;
  }
  if (!Array.isArray(properties)) {
    return properties[key] || defaultValue;
  }
  return (
    properties.find(
      (property) => property.label.toLowerCase() === key.toLowerCase()
    )?.value ?? defaultValue
  );
};

export const getLocationValueFromBomProperties = (properties) =>
  getValueFromBomProperties(properties, "location") ||
  getValueFromBomProperties(properties, "level") ||
  getValueFromBomProperties(properties, "baseLevel") ||
  getValueFromBomProperties(properties, "baseConstraint") ||
  getValueFromBomProperties(properties, "referenceLevel") ||
  getValueFromBomProperties(properties, "scheduleLevel") ||
  getValueFromBomProperties(properties, "offset") ||
  "";

export const flatTreeData = (treeData) => {
  let finalData = [];

  function toFlat(branchData) {
    branchData.forEach(({ children, ...restBranchData }) => {
      finalData.push(restBranchData);
      if (children?.length) {
        toFlat(children);
      }
    });
  }
  toFlat(treeData);
  return finalData;
};

export const formattedHousesOptions = (edges = []) => {
  let formattedOptions = [];

  if (edges.length) {
    formattedOptions = edges.map(({ node }) => ({
      id: node.id,
      displayName: node.displayName,
    }));
  }

  return formattedOptions;
};

// to making tree structure from the categorised options
export function makeTreeFromCategorisedOptions(categoryOptionsArr = []) {
  const deepCopyArr = JSON.parse(JSON.stringify(categoryOptionsArr));
  function getTree(arrayToFormat) {
    arrayToFormat.forEach((toFormatArrdata) => {
      if (toFormatArrdata?.children) {
        toFormatArrdata.children.forEach((children) => {
          // finding the children that are also a parent from the sections
          const findChildIndex = deepCopyArr.findIndex(
            (deepCopyArrData) =>
              deepCopyArrData?.id && deepCopyArrData.id === children.id
          );
          if (findChildIndex > -1) {
            children["children"] = deepCopyArr[findChildIndex].children;

            // making undefined the objects that has been put inside their parents so that it can be removed later by filter
            deepCopyArr[findChildIndex] = undefined;
          }
        });
      }
    });
  }

  getTree(deepCopyArr);

  //removing undefined childrens that we made above explicitly
  return deepCopyArr.filter(Boolean);
}
export function commonSuggestUsUrl(subject, emailId) {
  return `mailto:${emailId || bimmatchSupportEmail}?subject=${subject}`;
}

export const transformPropertyValueOptions = (propertyValueOptions) => {
  return propertyValueOptions.map((propertyValueOption) => {
    const metadataObj = {};
    const metadata = propertyValueOption.metadata;
    if (Array.isArray(metadata)) {
      metadata.forEach(
        (metadataItem) => (metadataObj[metadataItem.key] = metadataItem.value)
      );
    }
    return {
      ...propertyValueOption,
      metadataObj,
    };
  });
};

export const getLeafNodesFromTree = (treeData = []) => {
  const returnData = [];
  if (Array.isArray(treeData)) {
    treeData.forEach((data) => {
      if (!Array.isArray(data?.children)) {
        returnData.push(data);
      } else {
        returnData.push(...getLeafNodesFromTree(data.children).flat());
      }
    });
  }

  return returnData;
};


//
export const getCategoryAndSubCategoriesIdByName = (categories, categoryName) => {
  const obj = { categories: [], subCategories: [] };
  const category = categories.find((cat) => cat?.displayName === categoryName);

  if (category) {
    obj.categories = category.parentCategoryId ? [category.parentCategoryId] : [category.id]

    if (category.parentCategoryId) {
      obj.subCategories = [category.id];
    }
  }
  return obj;
}

const covertToCamelCase = (str) => str
  .split(/(?=[A-Z][a-z])/) // Split camelCase key into words
  .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
  .join(" ");

//this will handle string object and array
export const transformKeyToCamelCase = (data, keyField) => {
  if (!data) {
    return data
  }

  if (typeof data === "string") {
    return covertToCamelCase(data)
  }

  if (typeof data !== "object") {
    return data;
  }

  if (Array.isArray(data) && keyField) {
    return data.map(obj => {
      if (obj && typeof obj === "object" && Object.keys(obj).includes(keyField)) {
        return { ...obj, [keyField]: covertToCamelCase(obj[keyField]) }
      }
      return obj
    })
  }

  return Object.keys(data).reduce((acc, key) => {
    const newKey = covertToCamelCase(key);
    acc[newKey] = data[key]; // Assign value to the formatted key
    return acc;
  }, {})
}

export function filterEmptyOrNull(data, nullableValues = []) {
  const allNullableValues = [...nullableValues, null, undefined];

  if (allNullableValues.includes(data)) {
    return null;
  }

  // Early exit for non-object types
  if (typeof data !== 'object') {
    return data;
  }

  const isArray = Array.isArray(data);

  // If data is an object, apply filtering function to each property
  const filteredObject = isArray ? [] : {};

  for (const [key, value] of Object.entries(data)) {
    const filteredData = filterEmptyOrNull(value, nullableValues)
    if (filteredData && Object.keys(filteredData).length) {
      if (isArray) {
        filteredObject.push(filteredData)
      } else {
        filteredObject[key] = filteredData;
      }
    }
  }

  return filteredObject;
}

// convert key value pairs array to object
export const transformGraphqlKeyValuesToObject = (keyValueArr) => (Array.isArray(keyValueArr) ? keyValueArr : []).reduce((obj, data) => ({ ...obj, [data.key]: data.value }), {})

export const getFacetsQuery = (selectedFacetsLocal) => {
  let inputFacets = [];
  if (selectedFacetsLocal && selectedFacetsLocal.length > 0) {
    inputFacets = selectedFacetsLocal.reduce((acc, facet) => {
      const facetInput = acc.find((val) => val.id === facet.facetId);
      if (!facetInput) {
        return [...acc, { id: facet.facetId, values: [facet.id] }];
      } else {
        facetInput.values.push(facet.id);
      }
      return acc;
    }, []);
  }
  return inputFacets;
};

export const getSustainabilityPillarsDataFromMetadata = (metadata) => {
  const pillars = ["environmental", "economic", "social"]
  return pillars.map(pillar => ({
    id: pillar,
    value: metadata[pillar]
  }))
}

export const getParsedDate = (ts, formate = "LLL dd,yyyy  HH:mm") => {
  if (ts && !isNaN(ts)) {
    const dt = DateTime.fromMillis(+ts);
    if (dt.isValid) {
      return dt.toFormat(formate);
    }
  }
  return ""
}

// custom method incase toLocaleString not working
export function numberWithCommas(number) {
  return number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

export const isValidEmail = (email) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
