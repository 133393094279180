import React, { useCallback, useMemo, useState } from "react";
import InputText from "../input-text";
import { fieldValidation } from "../../utils";

const InputPassword = ({
    validations,
    label,
    onChange,
    isError,
    onValidationChange,
    strengthValidation = false,
    ...props
}) => {

    // local states
    const [showPassword, setShowPassword] = useState(false)
    const [hasError, setHasError] = useState(isError)
    const [strengthErrorData, setStrengthErrorData] = useState([])
    const [enableStrengthValidation, setEnabledStrengthValidation] = useState(false)

    const toggleShowPassword = useCallback(() => {
        setShowPassword(!showPassword)
    }, [showPassword])

    const onChangeInput = useCallback((fieldData, event) => {
        if (!enableStrengthValidation) {
            setEnabledStrengthValidation(true)
        }
        let value = event.target.value;
        const { isValid, message, allMessages } = fieldValidation(validations ? { ...validations, type: 'password' } : { type: 'password' }, value, label)
        if (!isValid) {
            if (!hasError) setHasError(true)
            if (allMessages) setStrengthErrorData(Object.keys(allMessages))
        } else {
            if (hasError) setHasError(false)
            if (strengthErrorData.length) setStrengthErrorData([])
        }
        onValidationChange && onValidationChange(isValid, message, value)
        onChange && onChange(fieldData, event)
    }, [enableStrengthValidation, hasError, label, onChange, onValidationChange, strengthErrorData.length, validations])

    return (
        <>
            <InputText
                {...props}
                label={label}
                info={strengthValidation ? <PasswordInfo validations={validations}/> : null}
                onChange={onChangeInput}
                validations={validations}
                onIconClick={toggleShowPassword}
                disableValidation={strengthValidation}
                type={showPassword ? "text" : "password"}
                iconSrc={`/images/icon/${showPassword ? "hide" : "show"}-eye.svg`}
                className={hasError ? "input-password-error input-text-error" : "input-password"}
            />
            {(enableStrengthValidation && strengthValidation) && <PasswordStrengthValidation errorData={strengthErrorData} validations={validations} />}
        </>

    );
}

const PasswordStrengthValidation = ({ errorData = [], validations = {} }) => {

    const strengthValidations = useMemo(() => validationTypes(validations), [validations])

    return (
        <div className="password-strength">
            {strengthValidations.map(strengthData => (
                <div key={strengthData.type} className={`single-strength ${!errorData.includes(strengthData.type) ? "strength-passed" : "strength-failed"}`}>
                    <img className="strength-icon" src={`/images/icon/${!errorData.includes(strengthData.type) ? "check": "warn-red"}.svg`} alt="validation" />
                    <span className="strength-message">{strengthData.description}</span>
                </div>
            ))}
        </div>
    )
}

function validationTypes(validations) {

    let allTypes = []

    const strengthTypes = {
        min: `At least ${validations?.min || 8} char`,
        specialCharacter: "At least 1 sign",
        number: "At least 1 num",
        capital: "At least 1 capital"
    }

    for (const strengthType in strengthTypes) {
        if (!validations?.[strengthType]) {
            continue;
        }

        allTypes.push({ type: strengthType, description: strengthTypes[strengthType] })
    }

    return allTypes
}

const PasswordInfo = ({ validations }) => (
    <div className='password-info'>
        <p className='heading'>Your password must comply with:</p>
        <ul className='requirement-list'>
            {validations?.min && <li>At least {validations?.min || 8} characters</li>}
            {validations?.number && <li>At least 1 number</li>}
            {validations?.capital && <li>At least 1 upper case (ABC)</li>}
            {validations?.specialCharacter && <li>At least 1 special sign (e.g. # $ @)</li>}
        </ul>
    </div>
)



export default InputPassword;
